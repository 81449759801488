<template>
  <c-flex
    flex="1"
    flex-dir="column"
    align-items="center"
    justify-content="center"
    ml="15px"
  >
    <c-flex
      w="100%"
      my="10px"
      align-items="center"
    >
      <c-text
        flex="1"
        font-weight="bold"
        font-size="18px"
        color="#111111"
      >
        Rencana makan
      </c-text>
      <c-button
        v-if="isNutri"
        variant-color="primary"
        border-radius="50px"
        as="router-link"
        :to="{ name: 'nutri.meal-plan.edit' }"
      >
        <c-image
          mr="10px"
          :src="require('@/assets/icon-pencil.svg')"
          alt="icon"
        />
        Edit
      </c-button>
    </c-flex>
    <c-flex
      flex="1"
      mt="20px"
      flex-dir="column"
      align-items="center"
      justify-content="center"
    >
      <c-image
        :src="require('./img-meal-plan-empty.svg')"
        alt="meal plan empty illustration"
        w="200px"
      />
      <h1
        v-chakra
        mt="10px"
        color="#111"
        font-weight="bold"
        font-size="16px"
        font-family="body"
      >
        Panduan makanmu masih kosong.
      </h1>
      <c-text
        mt="10px"
        max-w="328px"
        text-align="center"
        font-size="14px"
        color="#828282"
      >
        Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon
        ditunggu, ya!
      </c-text>
    </c-flex>
  </c-flex>
</template>

<script>
import isNutriMixin from '@/utils/isNutri-mixins'

export default {
  name: 'MealPlanEmpty',
  mixins: [isNutriMixin],
}
</script>
